<template>
    <div style="padding:20px;">
        <div class="title"><van-icon name="info" class="iconinfo" />我的订单</div>
        <div v-if="orderInfo!=null" class="orderinfo">
            <div class="orderinfoheader">
                <van-image
                width="60"
                height="60"
                round
                :src="wxUser.headImgUrl"
                />
                <div class="nickname">{{wxUser.nickname}}</div>
            </div>
            <div class="orderinfoitem">
                <P>订单号：{{orderInfo.orderno}}</P>
                <P>时间：{{orderInfo.etime | timeFilter}}</P>
                <P>姓名：{{orderInfo.name}}</P>
                <P>电话：{{orderInfo.mobile}}</P>
                <P>支付金额：{{orderInfo.price}}</P>
                <P>订单状态：{{orderInfo.status | statusFilter}}</P>
            </div>
        </div>
        <div class="tipsinfotipsinfo"><van-icon name="info" class="iconinfo" />请凭此订单信息于3月25至3月27日进店选购套系!</div>
        <div v-if="groupUserResp!=null" class="customerinfo">
            <div class="orderinfoitem">
                <P><van-icon name="phone"  style="margin-right:10px;"/>客服：{{groupUserResp.nickname}} 电话：{{groupUserResp.tel}}</P>
            </div>
        </div>
        <van-divider />

        <div v-if="tuanList.length>0">
            <div class="title"><van-icon name="info" class="iconinfo" />我的团队成员</div>
            <div class="tuanlist">
                <div v-for="(item,index) in tuanList" :key="index" class="orderinfoheader">
                    <van-image
                    width="60"
                    height="60"
                    round
                    :src="item.headImgUrl"
                    />
                    <div class="nickname">{{item.nickname}}</div>
                </div>
            
            </div>
            <div class="tipsinfotipsinfo">右上角分享给朋友，让更多的朋友一起参与组队!</div>
        </div>
        
        <van-divider />
        
        <div v-if="taoxi.length>0">
            <div class="title"><van-icon name="info" class="iconinfo"  />人气王套系</div>
        
            <van-card v-for="(item,index) in taoxi" :key="item.id"
            :tag="index==0? '人气王':''"
            :thumb="item.imgurl"
            style="background-color:#FFFFFF;border-radius:10px;padding:20px;"
            >
                <template #title>
                    <div style="font-weight:bold;font-size:16px;">{{item.name}}</div>
                </template>
                <template #desc>
                    <div class="rqdesc" v-html="item.desc">
                        {{item.desc}}
                    </div>
                </template>
                <template #price>
                    <div style="font-weight:bold;font-size:16px;">原价：<del>{{item.oldprice}}</del>  <span style="color:red"> 现价：{{item.price}}</span></div>
                </template>
                <template #footer>
                    <van-button  :disabled="haveCheck"  style="border-radius:10px;" icon="like-o"  type="danger" @click="taoxiCheck(item)" >选择套系</van-button>
                </template>
            </van-card>
        
        </div>
        
        <!-- <van-card
        price="2.00"
        desc="套系描述"
        title="套系名称"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
         style="background-color:#FFFFFF;border-radius:10px;"
        >
        <template #footer>
            <van-button round icon="like-o" disabled="true"  type="danger" >选择套系</van-button>
        </template>
        </van-card> -->
    </div>
</template>
<script>
import Qs from "qs"
import { parseTime }  from "../../utils/index"
import { Dialog } from 'vant';
import { getOrderInfo,checkTaoxi } from "../../api/index"
export default {
    name:"orderInfo",
    filters: {
      timeFilter(times) {
        return parseTime(times)
      },
      statusFilter(status) {
        const statusMap = {
          1: '已支付',
          0: '未支付',
          2: '已退款',
          4: '已核销',
          5: '已核销'
        }
        return statusMap[status]
      },
    },
     data(){
        return {
            query:null,
            activeid:null,
            groupid:null,
            wxUserInfo:null,
            orderInfo:null,
            wxUser:{
                headImgUrl:"https://files.uboototaobao.com/rxlogo.png",
                nickname:null
            },
            groupUserResp:null,
            taoxi:[],
            haveCheck:false,
            tuanList:[]
        }
    },
    metaInfo(){
        return {
            title:"我的订单"
        }
    },
    created(){

        // localStorage.setItem("wxUserInfo",'{"id":2,"openid":"oFf555g62r5qLwSw6tHj_mgNldsw","nickname":"肖明（小明）","headImgUrl":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJBn5iaPtNlx5QvZbevopll6sLREcXkUINQWtuv0Klg2RvicmhJ1lNfeLVv2h0mhT3DmqqJJqicVsRJg/132","ctime":"2022-03-13T08:26:57.000+00:00","etime":"2022-03-13T08:26:57.000+00:00","groupid":1,"activeid":1}')
        if(!localStorage.getItem("wxUserInfo")){
            this.$router.push({path:"/error"})
            return;
        }
        this.wxUserInfo=JSON.parse(localStorage.getItem("wxUserInfo"))
        //获取params内容
        this.query = Qs.parse(location.search.substring(1))
        this.activeid=this.query.activeid;
        this.groupid=this.query.groupid;
        this.tid=this.query.tid;
        console.log("我的订单页")
        console.log(this.query)

        //获取订单信息
        this.fetchOrderInfo()
        //获取团队成圆
        //获取人气王套系
    },
    methods:{
        async fetchOrderInfo(){
            var param={"activeid":this.activeid,"groupid":this.groupid,"uid":this.wxUserInfo.id,"url":window.location.href}
            const {code,data}=await getOrderInfo(param);
            if(code==99999){
                this.$router.push({path:"/error"})
                return;
            }

            this.orderInfo=data.orderinfo
            this.wxUser=data.wxUser
            this.groupUserResp=data.groupUserResp
            this.taoxi=data.taoxi
            this.haveCheck=data.haveCheck
            this.tuanList=data.tuanList
            this.$wxShare.updateWxShareConfig({
                title:data.activeData.wechattitle,
                desc:data.activeData.wechatdesc,
                link:data.link,
                imgUrl:data.activeData.wechaticon,
                appId:data.jssing.appId,
                timestamp:data.jssing.timestamp,
                nonceStr:data.jssing.nonceStr,
                signature:data.jssing.signature
            })

        },

        async taoxiCheck(ii){
            console.log(ii);
            var param={"uid":this.wxUserInfo.id,"activeid":this.activeid,"taoxiid":ii.id};
            console.log(param)
            const {code} =await checkTaoxi(param);

            if(code==0){
                this.haveCheck=true;
                Dialog.alert({
                message: "感谢您的选择！",
                })
            }
        }
    }
}
</script>

<style scoped>
.title{
    font-size:18px;
    font-weight: bold;
}
.iconinfo{
    margin-right:10px;
    color:red
}
.orderinfo{
    background-color:#FFFFFF;border-radius:10px;padding:10px;margin-top:20px;
    display:flex;
    flex-direction: row;
}
.tuanlist{
    background-color:#FFFFFF;border-radius:10px;padding:10px;margin-top:20px;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.orderinfoheader{
    width:100px;
    display:flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-top:20px;
    
}
.orderinfoitem{
    font-size:14px;
    line-height:14px;
}
.tipsinfotipsinfo{
    text-align:center;
    font-size:12px;
    margin-top:20px;
    color:red;
    font-weight: bold;
}

.customerinfo{
    background-color:#FFFFFF;border-radius:10px;padding:10px;margin-top:20px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.rqdesc>P{
    line-height:10px;
}
.nickname{
    font-size:12px;
    white-space:nowrap;
    overflow:hidden;
    width:80px;
    margin-top:20px;
    text-align:center;
}
</style>