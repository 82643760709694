<template>
    <div class="wrap">
        <img class="" src="../assets/404.png" alt="">
        <P>您所访问的页面不存在！</P>
        <P>请联系联系管理员</P>
    </div>
</template>
<script>
export default {
    name:"index",
    metaInfo(){
        return {
            title:"您所访问的页面不存在！"
        }
    },
    created(){
    },
    mounted(){

    },
    
}
</script>
<style scoped>
.wrap{
    height:100vh;
    background-color:#ffc451;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: bolder;
    color:#555555;

}
.wrap>img{
    width:300px;
}
</style>