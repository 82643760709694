import request from '../utils/request'

/**
 * 获取微信授权跳转地址
 * @param {*} data 
 * @returns 
 */
export function getOauthUrl(data){
    return request({
        url:'/wxMp/getOAuthUrl',
        method:'POST',
        data
    })
}
/**
 * 获取微信用户信息
 * @param {*} data 
 * @returns 
 */
export function getUserInfo(data){
    return request({
        url:'/wxMp/getUserInfo',
        method:'POST',
        data
    })
}
/**
 * 获取微信转发的授权信息
 * @param {*} data 
 * @returns 
 */
export function getJsApiSignature(data){
    return request({
        url:'/wxMp/getJsApiSignature',
        method:'POST',
        data
    })
}

/**
 * 获取首页整体要展示的信息
 * @param {*} data 
 * @returns 
 */
export function getIndex(data){
    return request({
        url:'/wxMp/getIndex',
        method:'POST',
        data
    })
}


/**
 * 创建订单
 * @param {*} data 
 * @returns 
 */
export function createOrder(data){
    return request({
        url:'/wxMp/createOrder',
        method:'POST',
        data
    })
}

/**
 * 获取弹幕
 * @param {*} data 
 * @returns 
 */
 export function bargOrder(data){
    return request({
        url:'/wxMp/bargOrder',
        method:'POST',
        data
    })
}

/**
 * 获取订单信息
 * @param {*} data 
 * @returns 
 */
 export function getOrderInfo(data){
    return request({
        url:'/wxMp/orderInfo',
        method:'POST',
        data
    })
}

/**
 * 选择人气套系
 * @param {*} data 
 * @returns 
 */
 export function checkTaoxi(data){
    return request({
        url:'/wxMp/checkTaoxi',
        method:'POST',
        data
    })
}