
import axios from 'axios'
import { baseURL,contentType } from '../config'

const requestConf = (config)=>{
    //这里做请求的拦截配置。如token配置等
    const token=localStorage.getItem("xiutoken");
    if(token) config.headers['Authorization'] = `${token}`
    return config;
}

/**
 * 初始化axios
 */
const instance = axios.create({
    baseURL,
    headers:{
        'Content-Type':contentType
    }
})

/**axios请求拦截器 */
instance.interceptors.request.use(requestConf,(error)=>{
    return Promise.reject(error)
})

instance.interceptors.response.use(
    response=>{
        const res=response.data
        return res
    }
)

export default instance