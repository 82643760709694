<template>
    <div>
        <van-empty description="暂时停用，请至PC端后台!" />
        <!-- <van-form @submit="onSubmit">
            <van-field
                v-model="jiaorder"
                name="jiaorder"
                type="number"
                label="虚拟订单数"
                placeholder="虚拟订单"
                :rules="[{ required: true, message: '请填写虚拟订单数' }]"
            />
            <van-field
                v-model="jiaviews"
                name="jiaviews"
                type="number"
                label="虚拟访问量"
                placeholder="虚拟访问量"
                :rules="[{ required: true, message: '请填写虚拟访问量' }]"
            />
            <van-field
                v-model="wechattitle"
                name="wechattitle"
                label="微信转发标题"
                placeholder="微信转发标题"
                :rules="[{ required: true, message: '请填写微信转发标题' }]"
            />
            <van-field
                v-model="wechatdesc"
                name="wechatdesc"
                label="微信转发简述"
                placeholder="微信转发简述"
                :rules="[{ required: true, message: '请填写微信转发简述' }]"
            />
        
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
        </van-form>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o"  @click="goToHome()">订单排行榜</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToOrder()" >全部订单</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToSetting()">链接设置</van-tabbar-item>
            <van-tabbar-item icon="friends-o" >人气套系</van-tabbar-item>
        </van-tabbar> -->
    </div>
</template>
<script>
import Qs from "qs"
import { Dialog } from 'vant';
import { editActive,GetActive } from "../../../api/laoshi"
export default {
    name:"login",
    metaInfo(){
        return {
            title:"软线网络微信营销系统[活动老师端]"
        }
    },
    data() {
        return {
            active:2,
            groupid:null,
            activeid:1,
            jiaorder:"",
            jiaviews:"",
            wechattitle:"",
            wechatdesc:""
        };
    },
    created(){
        //如果token不存在，则退出登录
        // if(localStorage.getItem("xiutoken")){
        //     this.xiutoken=localStorage.getItem("xiutoken");
        // }else{
        //     this.$router.push({path:"/houtai/login"})
        // }
        //获取params内容
        this.query = Qs.parse(location.search.substring(1))
        console.log(this.query);
        this.groupid=this.query.groupid;

         //获取用户基础信息
        this.fetchActive();
        
    },
    methods:{
        async onSubmit(values){
            console.log(values)
            values['activeid']=this.activeid;
            values['groupid']=this.groupid;
            const {msg} =await editActive(values);
    
            Dialog.alert({
                message: msg,
            })
        },
        async fetchActive(){
            var param={"activeid":this.activeid,"groupid":this.groupid}
            const {code,data} =await GetActive(param);
            console.log(code);
            console.log(data);
            this.jiaorder=data.jiaorder;
            this.jiaviews=data.jiaviews;
            this.wechattitle=data.wechattitle;
            this.wechatdesc=data.wechatdesc;
        },
        goToLink(){
            console.log("去推广链接")
        },
        goToHome(){
            this.$router.push({path:"/houtai/laoshi"})
        },
        goToOrder(){
            this.$router.push({path:"/houtai/laoshiorder"})
        },
        goToMyViews(){
            this.$router.push({path:"/houtai/myviews"})
        },
        goToHexiao(){
            this.$router.push({path:"/houtai/hexiao"})
        },
        goToSetting(){
            this.$router.push({path:"/houtai/laoshisetting"})
        }
    }
    
    
    
}
</script>
<style scoped>
.wrap{
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.orderuser{
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}
.orderinfo{
    display:flex;
    flex-direction: column;
    margin-top:20px;
}
.orderinfo>span{
    margin-bottom:10px;
    font-size:14px;
}
.custom-title{
    margin-right:10px;
}
</style>