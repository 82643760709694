import wx from "weixin-js-sdk"
import Vue from "vue"
const wxShareConfig={}

const wxShare={
    updateWxShareConfig(config={}){
        wxShareConfig.title=config.title;
        wxShareConfig.desc=config.desc;
        wxShareConfig.link=config.link;
        wxShareConfig.imgUrl=config.imgUrl;
        wxShareConfig.appId=config.appId;
        wxShareConfig.timestamp=config.timestamp;
        wxShareConfig.nonceStr=config.nonceStr;
        wxShareConfig.signature=config.signature;

        this._wxConfigJSSDK(wxShareConfig)
    },

    _wxConfigJSSDK(wxShareConfig){
        wx.config({
            debug:false,
            appId:wxShareConfig.appId,
            timestamp:wxShareConfig.timestamp,
            nonceStr:wxShareConfig.nonceStr,
            signature:wxShareConfig.signature,
            jsApiList:['updateAppMessageShareData','updateTimelineShareData']
        })
        wx.ready(function(){
            const {title,desc,link,imgUrl} =wxShareConfig;
            wx.updateAppMessageShareData({
                title,
                desc,
                link,
                imgUrl,
                success:function(){
                    console.log("分享好友成功")
                },
                fail:function(){
                    console.log("分享好友失败")
                },
            })
            wx.updateTimelineShareData({
                title,
                link,
                imgUrl,
                success:function(){
                    console.log("分享朋友圈成功")
                },
                fail:function(){
                    console.log("分享朋友圈失败")
                },
            })
        })
    }
}

export default wxShare;

Vue.prototype.$wxShare=wxShare;