<template>
    <div>
        <div v-if="list.length>0">
                <van-cell v-for="item in list" :key="item.id">
                    <template #title>
                        <span class="custom-title">最后访问:{{item.endViewTime | timeFilter}}</span>
                    </template>
                    <template #right-icon>
                        <div class="orderuser">
                            <van-image
                            width="50"
                            height="50"
                            round
                            :src="item.headImgUrl"
                            />
                        </div>
                        
                    </template>
                    <template #label>
                        <div class="orderinfo">
                            <span>微信昵称:{{item.nickname}}</span>
                            <span>访问次数:{{item.counts}}</span>
                        </div>
                    </template>
                </van-cell>
        </div>
        <van-empty v-else description="暂无访客" />
        
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o"  @click="goToHome()">首页</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToOrder()" >订单</van-tabbar-item>
            <van-tabbar-item icon="friends-o"  @click="goToSetting()">帐户设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
import Qs from "qs"
import { GetMyViews } from "../../../api/houtai"
import { parseTime }  from "../../../utils/index"
export default {
    name:"login",
    filters: {
      timeFilter(times) {
        return parseTime(times)
      },
      statusFilter(status) {
        const statusMap = {
          1: '已支付',
          0: '未支付',
          2: '已退款',
          4: '已核销',
          5: '已核销'
        }
        return statusMap[status]
      },
    },
    metaInfo(){
        return {
            title:"软线网络微信营销系统[员工端]"
        }
    },
    data() {
        return {
            active:0,
            groupid:null,
            activeid:1,
            list:[]
        };
    },
    created(){
        //如果token不存在，则退出登录
        if(localStorage.getItem("xiutoken")){
            this.xiutoken=localStorage.getItem("xiutoken");
        }else{
            this.$router.push({path:"/houtai/login"})
        }
        //获取params内容
        this.query = Qs.parse(location.search.substring(1))
        console.log(this.query);
        this.groupid=this.query.groupid;
        
        //获取访问者列表
        this.fetchMyViews();
        
    },
    methods:{
        async fetchMyViews(){
             var param={"activeid":this.activeid,"groupid":this.groupid}
             const {code,data}=await GetMyViews(param);
             console.log(data)
             if(code==0){
                this.list=data;
            }else{
                localStorage.removeItem("xiutoken")
                this.$router.push({path:"/houtai/login"})
            }
        },
        goToLink(){
            console.log("去推广链接")
        },
        goToHome(){
            this.$router.push({path:"/houtai/index"})
        },
        goToOrder(){
            this.$router.push({path:"/houtai/order"})
        },
        goToMyViews(){
            this.$router.push({path:"/houtai/myviews"})
        },
        goToHexiao(){
            this.$router.push({path:"/houtai/hexiao"})
        },
        goToSetting(){
            this.$router.push({path:"/houtai/setting"})
        }
    }
    
    
    
}
</script>
<style scoped>
.wrap{
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.orderuser{
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}
.orderinfo{
    display:flex;
    flex-direction: column;
    margin-top:20px;
}
.orderinfo>span{
    margin-bottom:10px;
    font-size:14px;
}
.custom-title{
    margin-right:10px;
}
</style>