import request from '../utils/request'

/**
 * 员工排行榜
 * @param {*} data 
 * @returns 
 */
export function GetYgPai(data){
    return request({
        url:'/laoshihoutai/getYgPaiAll',
        method:'POST',
        data
    })
}

/**
 * 员工排行榜
 * @param {*} data 
 * @returns 
 */
 export function GetAllOrder(data){
    return request({
        url:'/laoshihoutai/getAllOrder',
        method:'POST',
        data
    })
}



/**
 * 获取套系详情
 * @param {*} data 
 * @returns 
 */
 export function GetActive(data){
    return request({
        url:'/laoshihoutai/getActive',
        method:'POST',
        data
    })
}



/**
 * 修改套系
 * @param {*} data 
 * @returns 
 */
 export function editActive(data){
    return request({
        url:'/laoshihoutai/editActive',
        method:'POST',
        data
    })
}


