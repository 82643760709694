<template>
    <div>
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
            线下订单录入，一个手机号仅能生成一个订单！
        </van-notice-bar>
        <van-form @submit="onSubmit">
            <van-field
                v-model="name"
                name="name"
                label="客户姓名"
                placeholder="客户姓名"
                :rules="[{ required: true, message: '请填写客户姓名' }]"
            />
            <van-field
                v-model="mobile"
                name="mobile"
                type="number"
                label="手机号"
                placeholder="手机号"
                :rules="[{ required: true, message: '请填写手机号' }]"
            />
        
        
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
        </van-form>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o"  @click="goToHome()">首页</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToOrder()" >订单</van-tabbar-item>
            <van-tabbar-item icon="friends-o"  @click="goToSetting()">客服设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
import Qs from "qs"
import { Dialog } from 'vant';
import { AddUnderOrder } from "../../../api/houtai"
export default {
    name:"addUnderLineOrder",
    metaInfo(){
        return {
            title:"软线网络微信营销系统[员工端]"
        }
    },
    data() {
        return {
            active:0,
            groupid:null,
            activeid:1,
            name:"",
            mobile:"",
        };
    },
    created(){
        //如果token不存在，则退出登录
        if(localStorage.getItem("xiutoken")){
            this.xiutoken=localStorage.getItem("xiutoken");
        }else{
            this.$router.push({path:"/houtai/login"})
        }
        //获取params内容
        this.query = Qs.parse(location.search.substring(1))
        console.log(this.query);
        this.groupid=this.query.groupid;

        
    },
    methods:{
        async onSubmit(values){
            console.log(values)
            values['activeid']=1;
            values['groupid']=this.groupid;
            const {msg} =await AddUnderOrder(values);
        
            Dialog.alert({
                message: msg,
            })
            
        },
        
        goToLink(){
            console.log("去推广链接")
        },
        goToHome(){
            this.$router.push({path:"/houtai/index"})
        },
        goToOrder(){
            this.$router.push({path:"/houtai/order"})
        },
        goToMyViews(){
            this.$router.push({path:"/houtai/myviews"})
        },
        goToHexiao(){
            this.$router.push({path:"/houtai/hexiao"})
        },
        goToSetting(){
            this.$router.push({path:"/houtai/setting"})
        }
    }
    
    
    
}
</script>
<style scoped>
.wrap{
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.orderuser{
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}
.orderinfo{
    display:flex;
    flex-direction: column;
    margin-top:20px;
}
.orderinfo>span{
    margin-bottom:10px;
    font-size:14px;
}
.custom-title{
    margin-right:10px;
}
</style>