<template>
    <div>
        
        <van-row class="header">
            <van-col span="6">
                <van-image
                    round
                    width="60"
                    height="60"
                    src="https://files.uboototaobao.com/rxlogo.png"
                />
            </van-col>
            <van-col span="12"  class="headinfo">
                <P>昵称：{{nickname}}</P>
                <P>电话：{{tel}}</P>
                <P>所属分组：{{zhuname}}</P>
            </van-col>
            <van-col span="7">
                <van-button style="border-radius:10px;" type="danger" @click="logout()">退出登录</van-button>
            </van-col>
        </van-row>
        <van-cell-group>
            <van-cell title="功能导航"  icon="location-o" value="" />
        </van-cell-group>
        <van-grid :column-num="4" >
            <van-grid-item icon="star-o" @click="goToLink()" text="活动链接" />
            <van-grid-item icon="coupon-o" @click="goToOrder()" text="我的订单" />
            <van-grid-item icon="like-o" @click="goToMyViews()" text="我的访客" />
            <van-grid-item icon="cart-o" @click="goToHexiao()" text="订单核销" />
            <van-grid-item icon="friends-o" @click="goToAddUnderLineOrder()" text="线下录入" />
            <van-grid-item icon="friends-o" @click="goToSetting()" text="客服设置" />
            <!-- <van-grid-item icon="setting-o" @click="goToSetting()" text="修改密码" /> -->
        </van-grid>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o"  @click="goToHome()">首页</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToOrder()" >订单</van-tabbar-item>
            <van-tabbar-item icon="friends-o"  @click="goToSetting()">客服设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
import Qs from "qs"
import { GetUserInfo,GetActiveLink } from "../../../api/houtai"
export default {
    name:"login",
    metaInfo(){
        return {
            title:"软线网络微信营销系统[员工端]"
        }
    },
    data() {
        return {
            active:0,
            username: '',
            password: '',
            groupid:null,
            nickname:"",
            tel:"",
            zhuname:"",
            link:null,
        };
    },
    created(){
        //如果token不存在，则退出登录
        if(localStorage.getItem("xiutoken")){
            this.xiutoken=localStorage.getItem("xiutoken");
        }else{
            this.$router.push({path:"/houtai/login"})
        }
        //获取params内容
        this.query = Qs.parse(location.search.substring(1))
        console.log(this.query);
        this.groupid=this.query.groupid;

        //获取用户基础信息
        this.fetchUserInfo();
        //获取活动链接地址
        this.fetchActiveLink();
    
    },
    methods:{
        async fetchUserInfo(){
            const {code,data} =await GetUserInfo();
            console.log(code);
            console.log(data);
            if(code==0){
                this.nickname=data.nickname;
                this.tel=data.tel;
                this.zhuname=data.zhuname;
            }else{
                localStorage.removeItem("xiutoken")
                this.$router.push({path:"/houtai/login"})
            }
        },
        async fetchActiveLink(){
            const {data} =await GetActiveLink();

            this.link=data.link;

        },
        goToLink(){
            window.location.href=this.link
            console.log("去推广链接")
        },
        goToHome(){
            this.$router.push({path:"/houtai/index"})
        },
        goToOrder(){
            this.$router.push({path:"/houtai/order"})
        },
        goToMyViews(){
            this.$router.push({path:"/houtai/myviews"})
        },
        goToAddUnderLineOrder(){
             this.$router.push({path:"/houtai/addUnderLineOrder"})
        },
        goToHexiao(){
            this.$router.push({path:"/houtai/hexiao"})
        },
        goToSetting(){
            this.$router.push({path:"/houtai/setting"})
        },
        logout(){
            localStorage.removeItem("xiutoken")
            this.$router.push({path:"/houtai/login"})
            console.log("退出登录")
        }
    }
}
</script>
<style scoped>
.wrap{
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.loginform{
    margin-top:40px;
}
.header{
    background-color:#FFFFFF;padding:16px;margin-bottom:20px;display:flex;flex-direction:row;
    align-items: center;
}
.headinfo{
    font-size:14px;line-height:14px;
}
.headinfo>P{
    font-size:14px;line-height:12px;
}
</style>