import Vue from 'vue'
import App from './App.vue'

import router from './router'
import Meta from "vue-meta"
import Vant from 'vant'
import { vueBaberrage }  from 'vue-baberrage'


import 'vant/lib/index.css'
import '../utils/wxShare'

Vue.config.productionTip = false
Vue.use(Meta)
Vue.use(Vant)
Vue.use(vueBaberrage)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

