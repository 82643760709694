<template>

    <div>
      <vue-baberrage
       :isShow= "barrageIsShow"
      :barrageList = "barrageList"
      :loop = "barrageLoop"
      boxHeight="300"
      ></vue-baberrage>
      <van-popup :overlay="popupoverlay"  round="true" closeable  v-model="showPopup">
        <div class="customerpopup">
          <div class="customertitle">联系客服</div>
          <!-- <div class="customerQrcode" v-if="customer.qrcodeImg!=null">
            <img :src="customer.qrcodeImg" alt="">
          </div> -->
          <div class="customerTel" v-if="customer.tel != null && customer.tel!='' && customer.telshow==true"><a :href="'tel:'+customer.tel">{{customer.tel}}</a></div>
        </div>
      </van-popup>
      <div class="contentmain">
        <img :src="topimg" alt="">
        <div class="djs" v-bind:style="{ background: djsColor}">
          <div class="djsTitle">活动倒计时</div>
          <van-count-down :time="time">
            <template #default="timeData">
              <span class="block">{{ timeData.days }}</span>
              <span class="colon">天</span>
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">时</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">分</span>
              <span class="block">{{ timeData.seconds }}</span>
              <span class="colon">秒</span>
            </template>
          </van-count-down>
        </div>
        <div class="couuntinfo">
          <div>已订购<span>{{orderCount}}</span>人</div>
          <div>访问人次<span>{{showCount}}</span>次</div>
        </div>
        <div v-if="wxuser.length>0" class="viewshow">
          <div v-for="item in wxuser" :key="item.id" class="viewshowItem">
            <img :src="item.headImgUrl" alt="">
          </div>
        </div>
        <div class="content" v-html="content">{{content}}</div>
        
      </div>
<van-action-sheet v-model="showModel" title="订购">
  <div class="content">
    <van-form @submit="onSubmit">
  <van-field
    v-model="name"
    name="name"
    label="姓名"
    placeholder="请输入姓名"
    :rules="[{ required: true, message: '请填写姓名' }]"
  />
  <van-field
    v-model="mobile"
    type="number"
    name="mobile"
    label="手机号"
    placeholder="请输入手机号"
    :rules="[{ required: true, message: '请输入手机号' },{ pattern:/^1[3|4|5|6|7|8|9][0-9]\d{8}$/,message:'请输入正确的手机号码',trigger:'blur'}]"
  />
  <div style="margin: 16px;">
    <van-button round block type="danger" native-type="submit">提交支付</van-button>
  </div>
</van-form>
  </div>

</van-action-sheet>
        <van-goods-action>
          <van-goods-action-icon icon="chat-o" text="客服"  @click="ShowCustomer"/>
          <van-goods-action-button
            type="danger"
            text="立即购买"
            @click="onClickButton"
          />
        </van-goods-action>


<div v-if="haveOrder" class="myorder" @click="goOrderInfo">
  <van-icon name="todo-list-o" class="ordericon" />
  我的订单
</div>

    </div>


    
</template>



<script>
import Qs from "qs"
import { Toast,Dialog } from 'vant';
import { WeixinJSBridge } from "weixin-js-sdk"
import { MESSAGE_TYPE } from 'vue-baberrage'
import { getOauthUrl,getUserInfo,getIndex,createOrder,bargOrder } from "../../api/index"
export default {
    name:"index",
    metaInfo(){
        return {
          
            title:this.title
        }
    },
     data(){
    return {
      msg:"ABC",
      popupoverlay:false,
      barrageCurrentId:0,
      barrageIsShow: true,
            barrageLoop: true,
            barrageList: [],
      showPopup:false,
      djsColor:"#ea8b02",
      time: 0,
      title:"小阿福春季特惠",
      name:"",
      mobile:"",
      showModel:false,
      protocol:null,
      host:null,
      query:null,
      wxUserInfo:null,
      activeid:null,
      groupid:null,
      content:"",
      topimg:"",
      prepay_data:null,
      wxuser:[],
      haveOrder:false,
      orderCount:0,
      showCount:0,
      customer:{
                qrcodeImg:"https://files.uboototaobao.com/rxlogo.png"
            },
    }
  },
  mounted(){
    console.log("mounted")
    this.getBarge()
  },
  created(){

    console.log("craeted")
    //获取域名
    this.host=window.location.host;
    //获取协议
    this.protocol=window.location.protocol;
    //获取params内容
    this.query = Qs.parse(location.search.substring(1))

    console.log(this.query);

    //判断是否有公司ID和活动ID还有推荐员工ID，如果没有则直接跳转非法页面。
    if(!this.query.groupid || !this.query.activeid || !this.query.tid){
        this.$router.push("/error")
        return;
    }

    this.activeid=this.query.activeid;
    this.groupid=this.query.groupid;
    console.log(this.query);
    if(this.query.code!=null){
        console.log(this.query.code)
        console.log("有CODE,需要用code去换取accessTOKEN")
        if(!localStorage.getItem("wxUserInfo")){
             console.log("获取授权")
            this.codeToUserInfo(this.query.code)
        }else{
          console.log("直接获取信息")
          this.wxUserInfo=JSON.parse(localStorage.getItem("wxUserInfo"))
          this.fetchData()
        }
    }else{
        this.fetchOauthUrl()
    }
  },
  methods:{
    goOrderInfo(){
      let vm=this;
      vm.$router.push({path:'/orderInfo',param:{'activeid':vm.activeid,'groupid':vm.groupid}})
    },
    //这里可以改成，一次性获取整个页面的所有信息
    async fetchData(){
      delete this.query["code"]
      delete this.query["state"]

      if(this.wxUserInfo.id){
        this.query['uid']=this.wxUserInfo.id
      }
      this.query['url']=window.location.href;
      let param=this.query;
      param['url']=window.location.href;
      // if(this.wxUserInfo.id){
      //   param['uid']=this.wxUserInfo.id;
      // }
      param['uid']=this.wxUserInfo.id;
      console.log(param);
      const { code,data } =await getIndex(param);

      if(code==99999){
        this.$router.push({path:"/error"})
        return;
      }
      console.log(data)
      this.time=data.times
      this.tuanid=data.tuanid
      this.query['tuanid']=data.tuanid
      this.topimg=data.activeData.topimages;
      this.content=data.activeData.content;
      this.customer=data.customer;
      this.wxuser=data.wxuser;
      this.showCount=data.showCount;
      this.orderCount=data.orderCount;
      this.haveOrder=data.haveOrder;
      this.$wxShare.updateWxShareConfig({
          title:data.activeData.wechattitle,
          desc:data.activeData.wechatdesc,
          link:data.link,
          imgUrl:data.activeData.wechaticon,
          appId:data.jsapiSignature.appId,
          timestamp:data.jsapiSignature.timestamp,
          nonceStr:data.jsapiSignature.nonceStr,
          signature:data.jsapiSignature.signature
        })
    },

    async getBarge(){

      var parm={"activeid":this.activeid,"groupid":this.groupid};
      const { data } =await bargOrder(parm);
      console.log(data);

      if(data.length>0){
        let vm=this;
        data.forEach((v)=>{
                vm.barrageList.push({
                    id:++vm.barrageCurrentId,
                    avatar:v.avatar,
                    msg:v.nickname+"刚刚订购了!",
                    time:v.time,
                    type:MESSAGE_TYPE.NORMAL,
                })
            })
            console.log(vm.barrageList)
      }
      
    },

    //提交
    async onSubmit(values){
      const loadding=Toast.loading({
        message: '努力提交中...',
        forbidClick: true,
        duration:0,
        overlay:true
      });
      console.log("submit",values);
      values['activeid']=this.activeid;
      values['groupid']=this.groupid;
      values['tid']=this.query.tid;
      values['uid']=this.wxUserInfo.id;
      values['tuanid']=this.tuanid;

      const {code,msg,data}=await createOrder(values);
      loadding.clear();
      if(code==99999){
        this.$router.push({path:"/error"})
        return;
      }

      console.log(code);
      console.log(msg);
      console.log(data);
      
      if(code==0){
        console.log("微信支付调起")
        this.prepay_data=data;
        let vm=this;
        if(typeof WeixinJSBridge =="undefined"){
          console.log("WeixinJSBridge UnderFined")
          if(document.addEventListener){
            document.addEventListener(
              'WeixinJSBridgeReady',
              vm.onBridgeReady(),
              false
            );
          }else if(document.attachEvent){
            document.attachEvent(
                'WeixinJSBridgeReady',
                this.onBridgeReady()
              );
              document.attachEvent(
                'onWeixinJSBridgeReady',
                vm.onBridgeReady()
              );
          }else{
            vm.onBridgeReady();
          }
        }
      }else{
        Dialog({ message: msg });
      }

    },
    onBridgeReady(){
      console.log("开始执行微信支付")
      let vm=this;
      console.log(vm.prepay_data)
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
                "appId":vm.prepay_data.appId,
                "nonceStr":vm.prepay_data.nonceStr,
                "package":vm.prepay_data.packageValue,
                "signType":vm.prepay_data.signType,
                "timeStamp":vm.prepay_data.timeStamp,
                "paySign":vm.prepay_data.paySign
            },
        function(res) {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            //支付成功
            vm.$router.push({path:'/orderInfo',param:{'activeid':vm.activeid,'groupid':vm.groupid}})
          } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
            console.log("取消支付")
          } else if(res.err_msg == "get_brand_wcpay_request:fail"){
                //支付失败
                console.log('支付失败');

                window.WeixinJSBridge.call('closeWindow');
             } //使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok,但并不保证它绝对可靠。
        }
      );
    },


    ShowCustomer(){
      this.showPopup=true
    },
    onClickButton(){
      this.showModel=true
    },
      goPage(){
        this.$router.push({name:"about",params:{activeid:this.activeid,groupid:this.groupid}})
      },
    //页面跳转微信授权
    async fetchOauthUrl(){
      delete this.query["code"]
      delete this.query["state"]
      // var url=this.protocol+"//"+this.host+"/?"+Qs.stringify(this.query);
      var params={"url":window.location.href,"activeid":this.activeid,"groupid":this.groupid}
      const { code,msg } =await getOauthUrl(params);
      console.log(code)
      if(code==0){
        window.location.href=msg
      }
      if(code==99999){
        this.$router.push({path:"/error"})
      }
    },

    async codeToUserInfo(codeString){
      console.log("code去换取策信ID")
      var par={"code":codeString,"activeid":this.activeid,"groupid":this.groupid}
      const{code,data} =await getUserInfo(par);
      console.log(data)
      console.log(code)
      if(code!=0){
        console.log(this.query);
        delete this.query["code"]
        delete this.query["state"]
        this.fetchOauthUrl()
      }else{
        this.wxUserInfo=data;
        localStorage.setItem("wxUserInfo",JSON.stringify(data))
        //获取微信JSSDK
        this.fetchData();
      }
    },
    

  }
}
</script>
<style scoped>
.contentmain>img{
  width:100%;
  display:block;
}
.content>>>img{
  width:100%;
  display:block;
}
.djs{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#FFFFFF;
  font-size:90px;
  padding:20px;
}
.van-count-down{
  color:#FFFFFF;
  font-size: 25px;
  line-height:35px;
}
.djsTitle{
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: bolder;
    color: #000000;
}
.colon {
    display: inline-block;
    margin: 0 4px;
    color: #000000;
    
}
.block {
    border-radius:5px;
    display: inline-block;
    padding-left:10px;
    padding-right:10px;
    color: #fff;
    font-size: 25px;
    text-align: center;
    background-color: #ee0a24;
}
.couuntinfo{
  height:50px;
  line-height: 50px;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ee0a24;
  color:#FFFFFF;
  font-weight: bold;
  padding-left:20px;
  padding-right:20px;
  box-sizing: border-box;
}
.viewshow{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top:10px;
}
.viewshowItem{
  width:75px;
  display:flex;
  flex-direction: column;
      justify-content: center;
    align-items: center;
    padding-bottom:10px;
}
.viewshowItem>img{
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 100%;
}
.viewshowItem>P{
  font-size:12px;
}
.customerpopup{
      width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.customertitle{
      font-weight: bold;
}
.customerQrcode{
      width: 180px;
}
.customerQrcode>img{
      width: 180px;
}

.myorder{
  position: fixed;
  right:10px;
  bottom:100px;
  z-index:10000;
  display:flex;
  flex-direction: column;
  font-size:14px;
  align-items: center;
}
.ordericon{
  font-size:40px;
  color:red;
}

</style>