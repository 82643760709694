import Vue from "vue"
import Router from "vue-router"

import Index from "./view/index"
import About from "./view/about"
import OrderInfo from "./view/orderInfo"
import Error from "./view/error"

import HouTaiLogin from "./view/houtai/login.vue"
import HouTaiIndex from "./view/houtai/index.vue"
import HouTaiOrder from "./view/houtai/orderList.vue"
import HouTaiMyViews from "./view/houtai/myviews.vue"
import HouTaiSetting from "./view/houtai/setting.vue"
import HouTaiHexiao from "./view/houtai/hexiao.vue"
import HouTaiAddUnderOrder from "./view/houtai/addunderorder.vue"

import LaoshiIndex from "./view/laoshi/index.vue"
import LaoshiOrder from "./view/laoshi/orderList.vue"
import LaoshiSetting from "./view/laoshi/setting.vue"
Vue.use(Router)

const router = new Router({
    // mode: 'history',
    routes:[
        {
            path:"/",
            name:'index',
            component:Index,
        },
        {
            path:"/orderInfo",
            name:'orderInfo',
            component:OrderInfo,
        },
        {
            path:"/about",
            name:'about',
            component:About,
        },
        {
            path:"/error",
            name:'error',
            component:Error,
        },
        {
            path:"/houtai/login",
            name:'login',
            component:HouTaiLogin,
        },
        {
            path:"/houtai/index",
            name:'houtaiindex',
            component:HouTaiIndex,
        },{
            path:"/houtai/order",
            name:'houtaiorder',
            component:HouTaiOrder,
        },{
            path:"/houtai/setting",
            name:'houtaisetting',
            component:HouTaiSetting,
        },{
            path:"/houtai/myviews",
            name:'houtaimyviews',
            component:HouTaiMyViews,
        },
        {
            path:"/houtai/hexiao",
            name:'houtaihexiao',
            component:HouTaiHexiao,
        },
        {
            path:"/houtai/addUnderLineOrder",
            name:'addUnderLineOrder',
            component:HouTaiAddUnderOrder,
        },
        {
            path:"/houtai/laoshi",
            name:'laoshiindex',
            component:LaoshiIndex,
        },
        {
            path:"/houtai/laoshiorder",
            name:'laoshiorder',
            component:LaoshiOrder,
        },
        {
            path:"/houtai/laoshisetting",
            name:'laoshisetting',
            component:LaoshiSetting,
        }
    ]
})

export default router;