<template>
    <div>这是跳转后页面</div>
</template>
<script>
import { getJsApiSignature } from "../../api/index"
import Qs from "qs"
export default {
    name:"about",
     data(){
    return {
      activeid:null,
      groupid:null,
    }
    },
    metaInfo(){
        return {
            title:"跳转页"
        }
    },
    created(){
        //获取params内容
    this.query = Qs.parse(location.search.substring(1))
    console.log(this.query);
    if(!this.query.groupid || !this.query.activeid){
        this.$router.push("/error")
        return;
    }

    },
    mounted(){

    },
    methods:{
         async changeWxShare() {
           var url=window.location.href;
      //这里需要改一下，需要传递活动ID，以让后端返回具体的微信转发的内容
        let par={"url":url}
            const { data } =await getJsApiSignature(par);
            console.log(data)
            this.$wxShare.updateWxShareConfig({
            title:"标题11",
            desc:"简介1",
            link:data.link,
          imgUrl:"https://files.uboototaobao.com/iconbase.png",
          appId:data.jsapiSignature.appId,
          timestamp:data.jsapiSignature.timestamp,
          nonceStr:data.jsapiSignature.nonceStr,
          signature:data.jsapiSignature.signature
            })
        }
    }
}
</script>