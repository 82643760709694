<template>
    <div>
        <van-empty description="暂时停用，请至PC端后台!" />
        <!-- <van-tabs v-model="baractive">
            <van-tab title="全部订单">
                <div v-if="allOrder.length>0">
                    <van-cell v-for="item in allOrder" :key="item.id">
                    <template #title>
                        <span class="custom-title">订单号:{{item.orderno}}</span>
                        <van-tag type="primary">{{item.status | statusFilter}}</van-tag>
                    </template>
                    <template #right-icon>
                        <div class="orderuser">
                            <van-image
                            width="50"
                            height="50"
                            round
                            :src="item.headImgUrl"
                            />
                            <span>{{item.nickname}}</span>
                        </div>
                        
                    </template>
                    <template #label>
                        <div class="orderinfo">
                            <span>姓名:{{item.name}}</span>
                            <span>电话: <a :href="'tel:'+item.mobile">{{item.mobile}}</a></span>
                            <span>订单时间:{{item.etime |timeFilter}}</span>
                            <span>推荐员工:{{item.ygname}}</span>
                        </div>
                    </template>
                </van-cell>
                </div>
                <div v-else>
<van-empty description="暂无订单" />
                </div>
                
            </van-tab>

            <van-tab title="已核销">
                <van-empty description="暂无订单" />
            </van-tab>
        </van-tabs> -->
        
        <!-- <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o"  @click="goToHome()">订单排行榜</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToOrder()" >全部订单</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToSetting()">链接设置</van-tabbar-item>
            <van-tabbar-item icon="friends-o" >人气套系</van-tabbar-item>
        </van-tabbar> -->
    </div>
</template>
<script>
import Qs from "qs"
import { GetAllOrder } from "../../../api/laoshi"
import { parseTime }  from "../../../utils/index"
export default {
    name:"login",
    filters: {
      timeFilter(times) {
        return parseTime(times)
      },
      statusFilter(status) {
        const statusMap = {
          1: '已支付',
          0: '未支付',
          2: '已退款',
          4: '已核销',
          5: '已核销'
        }
        return statusMap[status]
      },
    },
    metaInfo(){
        return {
            title:"软线网络微信营销系统[活动老师端]"
        }
    },
    data() {
        return {
            baractive:0,
            active:1,
            groupid:null,
            allOrder:[],
            activeid:1
        };
    },
    created(){
        
        //获取params内容
        this.query = Qs.parse(location.search.substring(1))
        console.log(this.query);
        this.groupid=this.query.groupid;
        //获取全部订单信息
        this.fetchAllOrder();

    },
    methods:{
        async fetchAllOrder(){
            var param={"activeid":this.activeid,"groupid":this.groupid}
            const {data} =await GetAllOrder(param);
            console.log(data)
            this.allOrder=data;
        },
        goToLink(){
            console.log("去推广链接")
        },
        goToHome(){
            this.$router.push({path:"/houtai/laoshi"})
        },
        goToOrder(){
            this.$router.push({path:"/houtai/laoshiorder"})
        },
        goToMyViews(){
            this.$router.push({path:"/houtai/myviews"})
        },
        goToHexiao(){
            this.$router.push({path:"/houtai/hexiao"})
        },
        goToSetting(){
            this.$router.push({path:"/houtai/laoshisetting"})
        }
    }
    
    
    
}
</script>
<style scoped>
.wrap{
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.orderuser{
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}
.orderinfo{
    display:flex;
    flex-direction: column;
    margin-top:20px;
}
.orderinfo>span{
    margin-bottom:10px;
    font-size:14px;
}
.custom-title{
    margin-right:10px;
}
</style>