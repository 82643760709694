import request from '../utils/request'

/**
 * 登录
 * @param {*} data 
 * @returns 
 */
export function DoLogin(data){
    return request({
        url:'/houtai/login',
        method:'POST',
        data
    })
}


/**
 * 用户信息
 * @param {*} data 
 * @returns 
 */
 export function GetUserInfo(){
    return request({
        url:'/houtai/userinfo',
        method:'POST',
    })
}

/**
 * 获取推广链接
 * @param {*} data 
 * @returns 
 */
 export function GetActiveLink(){
    return request({
        url:'/houtai/getActiveLink',
        method:'POST',
    })
}

/**
 * 我的订单信息
 * @param {*} data 
 * @returns 
 */
 export function GetMyOrder(data){
    return request({
        url:'/houtai/getMyOrder',
        method:'POST',
        data
    })
}

/**
 * 我的访客信息
 * @param {*} data 
 * @returns 
 */
 export function GetMyViews(data){
    return request({
        url:'/houtai/getMyViews',
        method:'POST',
        data
    })
}


/**
 * 设置客服电话
 * @param {*} data 
 * @returns 
 */
 export function setCustomer(data){
    return request({
        url:'/houtai/setCustomer',
        method:'POST',
        data
    })
}


/**
 * 新增线下订单
 * @param {*} data 
 * @returns 
 */
 export function AddUnderOrder(data){
    return request({
        url:'/houtai/addUnderLineOrder',
        method:'POST',
        data
    })
}