<template>
    <div>
        <van-empty description="暂未开放" />
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o"  @click="goToHome()">首页</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToOrder()" >订单</van-tabbar-item>
            <van-tabbar-item icon="friends-o"  @click="goToSetting()">帐户设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
import Qs from "qs"
export default {
    name:"login",
    metaInfo(){
        return {
            title:"软线网络微信营销系统[员工端]"
        }
    },
    data() {
        return {
            active:0,
            groupid:null,
        };
    },
    created(){
        //获取params内容
        this.query = Qs.parse(location.search.substring(1))
        console.log(this.query);
        this.groupid=this.query.groupid;
        
    },
    methods:{
        goToLink(){
            console.log("去推广链接")
        },
        goToHome(){
            this.$router.push({path:"/houtai/index"})
        },
        goToOrder(){
            this.$router.push({path:"/houtai/order"})
        },
        goToMyViews(){
            this.$router.push({path:"/houtai/myviews"})
        },
        goToHexiao(){
            this.$router.push({path:"/houtai/hexiao"})
        },
        goToSetting(){
            this.$router.push({path:"/houtai/setting"})
        }
    }
    
    
    
}
</script>
<style scoped>
.wrap{
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.orderuser{
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}
.orderinfo{
    display:flex;
    flex-direction: column;
    margin-top:20px;
}
.orderinfo>span{
    margin-bottom:10px;
    font-size:14px;
}
.custom-title{
    margin-right:10px;
}
</style>