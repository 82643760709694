<template>
    <div>
        <van-form @submit="onSubmit">
            <van-field
                v-model="tel"
                name="tel"
                type="number"
                label="客服电话"
                placeholder="客服电话"
                :rules="[{ required: true, message: '请填写客服电话' }]"
            />
        
        
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
        </van-form>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o"  @click="goToHome()">首页</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToOrder()" >订单</van-tabbar-item>
            <van-tabbar-item icon="friends-o"  @click="goToSetting()">客服设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
import Qs from "qs"
import { Dialog } from 'vant';
import { GetUserInfo,setCustomer } from "../../../api/houtai"
export default {
    name:"login",
    metaInfo(){
        return {
            title:"软线网络微信营销系统[员工端]"
        }
    },
    data() {
        return {
            active:2,
            groupid:null,
            activeid:1,
            nickname:"",
            tel:"",
            zhuname:"",
        };
    },
    created(){
        //如果token不存在，则退出登录
        if(localStorage.getItem("xiutoken")){
            this.xiutoken=localStorage.getItem("xiutoken");
        }else{
            this.$router.push({path:"/houtai/login"})
        }
        //获取params内容
        this.query = Qs.parse(location.search.substring(1))
        console.log(this.query);
        this.groupid=this.query.groupid;

         //获取用户基础信息
        this.fetchUserInfo();
        
    },
    methods:{
        async onSubmit(values){
            console.log(values)
            const {msg} =await setCustomer(values);
        
            Dialog.alert({
                message: msg,
            })
            
        },
        async fetchUserInfo(){
            const {code,data} =await GetUserInfo();
            console.log(code);
            console.log(data);
            if(code==0){
                this.nickname=data.nickname;
                this.tel=data.tel;
                this.zhuname=data.zhuname;
            }else{
                localStorage.removeItem("xiutoken")
                this.$router.push({path:"/houtai/login"})
            }
        },
        goToLink(){
            console.log("去推广链接")
        },
        goToHome(){
            this.$router.push({path:"/houtai/index"})
        },
        goToOrder(){
            this.$router.push({path:"/houtai/order"})
        },
        goToMyViews(){
            this.$router.push({path:"/houtai/myviews"})
        },
        goToHexiao(){
            this.$router.push({path:"/houtai/hexiao"})
        },
        goToSetting(){
            this.$router.push({path:"/houtai/setting"})
        }
    }
    
    
    
}
</script>
<style scoped>
.wrap{
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.orderuser{
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}
.orderinfo{
    display:flex;
    flex-direction: column;
    margin-top:20px;
}
.orderinfo>span{
    margin-bottom:10px;
    font-size:14px;
}
.custom-title{
    margin-right:10px;
}
</style>