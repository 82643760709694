<template>
    <div class="wrap">
        <van-image
        width="100"
        height="100"
        round
        src="https://files.uboototaobao.com/rxlogo.png"
        />
        <P>微信营销系统[员工端]</P>
        <div class="loginform"> 
            <van-form @submit="onSubmit">
            <van-field
                v-model="username"
                name="username"
                label="手机号"
                placeholder="请输入手机号"
                :rules="[{ required: true, message: '请输入手机号' }]"
            />
            <van-field
                v-model="password"
                type="password"
                name="password"
                label="密码"
                placeholder="密码"
                :rules="[{ required: true, message: '请填写密码' }]"
            />
            <div style="margin-top: 40px;">
                <van-button  block type="danger" native-type="submit">提交</van-button>
            </div>
            </van-form>
        </div>
        
    </div>
</template>
<script>
import Qs from "qs"
import { Dialog } from 'vant';
import { DoLogin } from "../../../api/houtai"
export default {
    name:"login",
    metaInfo(){
        return {
            title:"软线网络微信营销系统[员工端]"
        }
    },
    data() {
        return {
            username: '',
            password: '',
            groupid:null,
        };
    },
    created(){
        //获取params内容
        this.query = Qs.parse(location.search.substring(1))
        console.log(this.query);
        if(!this.query.groupid){
            this.$router.push({path:"/error"})
            return;
        }else{
            this.groupid=this.query.groupid;
        }
        
        
    },
    methods:{
        //登录请求,获取TOKEN。
        async onSubmit(values){
            console.log(values)
        
            values['groupid']=this.groupid;
            const {code,data,msg}=await DoLogin(values);
            console.log(code);
            console.log(data);

            if(code==0){
                localStorage.setItem('xiutoken',data.token);
                this.$router.push({path:"/houtai/index"})
            }else{
                Dialog.alert({
                message: msg,
                })
            }
        },

    }
    
    
    
}
</script>
<style scoped>
.wrap{
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.loginform{
    margin-top:40px;
}
</style>