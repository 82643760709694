<template>
    <div>
        <van-empty description="暂时停用，请至PC端后台!" />
<!--        
        <van-cell-group>
            <van-cell title="功能导航"  icon="location-o" value="" />
        </van-cell-group>
        <van-grid :column-num="4" >
            <van-grid-item icon="star-o" @click="goToLink()" text="订单排行榜" />
            <van-grid-item icon="coupon-o" @click="goToOrder()" text="链接设置" />
            <van-grid-item icon="like-o" @click="goToMyViews()" text="人气套系" />
        </van-grid> -->
        <!-- <van-cell-group>
            <van-cell title="订单排行"  icon="location-o" value="" />
        </van-cell-group>
        <div><van-cell v-for="item in list" :key="item.id" :title="item.nickname" :value="item.showcount" /></div>
        <div></div>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o"  @click="goToHome()">订单排行榜</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToOrder()" >全部订单</van-tabbar-item>
            <van-tabbar-item icon="search"  @click="goToSetting()">链接设置</van-tabbar-item>
            <van-tabbar-item icon="friends-o" >人气套系</van-tabbar-item>
        </van-tabbar> -->
    </div>
</template>
<script>
import Qs from "qs"
import { GetYgPai } from "../../../api/laoshi"
export default {
    name:"login",
    metaInfo(){
        return {
            title:"软线网络微信营销系统[活动老师端]"
        }
    },
    data() {
        return {
            active:0,
            username: '',
            password: '',
            groupid:null,
            nickname:"",
            tel:"",
            zhuname:"",
            link:null,
            activeid:1,
            list:[]
        };
    },
    created(){
        //如果token不存在，则退出登录
        // if(localStorage.getItem("xiutoken")){
        //     this.xiutoken=localStorage.getItem("xiutoken");
        // }else{
        //     this.$router.push({path:"/houtai/login"})
        // }
        //获取params内容
        this.query = Qs.parse(location.search.substring(1))
        console.log(this.query);
        this.groupid=this.query.groupid;

        //获取排行榜
        this.fetchPai();
    
    },
    methods:{
        async fetchPai(){
            var param={"activeid":this.activeid,"groupid":this.groupid}
            const {data} =await GetYgPai(param);
            console.log(data)
            this.list=data;
        },
        
        goToLink(){
            window.location.href=this.link
            console.log("去推广链接")
        },
        goToHome(){
            this.$router.push({path:"/houtai/index"})
        },
        goToOrder(){
            this.$router.push({path:"/houtai/laoshiorder"})
        },
        goToMyViews(){
            this.$router.push({path:"/houtai/myviews"})
        },
        goToHexiao(){
            this.$router.push({path:"/houtai/hexiao"})
        },
        goToSetting(){
            this.$router.push({path:"/houtai/laoshisetting"})
        },
        logout(){
            localStorage.removeItem("xiutoken")
            this.$router.push({path:"/houtai/login"})
            console.log("退出登录")
        }
    }
}
</script>
<style scoped>
.wrap{
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.loginform{
    margin-top:40px;
}
.header{
    background-color:#FFFFFF;padding:16px;margin-bottom:20px;display:flex;flex-direction:row;
    align-items: center;
}
.headinfo{
    font-size:14px;line-height:14px;
}
.headinfo>P{
    font-size:14px;line-height:12px;
}
</style>